import { Links, Scripts, LiveReload } from '@ubo/losse-sjedel'
import { AdminPanel, LosseLink } from '@ubo/losse-sjedel'
import BackgroundWithIcons from '../elements/BackgroundWithIcons'
import { Background } from '../flex/Banner/BannerLanding'

export default function PageNotFound() {
  return (
    <html lang="nl" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="h-full">
        <div className="bg-rc-litten h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="absolute right-0 top-0 z-0">
            <BackgroundWithIcons />
          </div>

          <Background />

          <div className="max-w-max mx-auto">
            <main className="sm:flex pt-32 sm:pt-52 md:pt-0">
              <p className="font-bold text-rc-quilladin text-5xl">404</p>
              <div className="sm:ml-6 max-sm:mt-4">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-2xl font-extrabold tracking-tight sm:text-5xl">Pagina niet gevonden</h1>
                  <div className="content mt-1">
                    <p>Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                  </div>
                </div>
                <div className="mt-6 sm:mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <LosseLink to="/" className="btn">
                    Ga naar home
                  </LosseLink>
                  <LosseLink to="/contact" className="btn--dark">
                    Contact
                  </LosseLink>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
